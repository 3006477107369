<template>
  <el-dialog title="原因" :visible.sync="showFail" width="504px" class="dialog-vertical">
    <el-input type="textarea" :rows="5" placeholder="请输入不通过理由(字符不能超过60个)" resize="none" maxlength="60"
      v-model="no_used_reason">
    </el-input>
    <div class="dialog-bottom">
      <el-button size="small" @click="closeModal">取消</el-button>
      <el-button size="small" type="primary" @click="handlePostReason" :loading="saveLoading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {failExamine} from "../../api/examine-list";

  export default {
  name: "NotFail",
  props: ["showFail", "id"],
  data() {
    return {
      no_used_reason: "",
      saveLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeNotFail");
    },
    handlePostReason() {
      let data = {
        id: this.id,
        reason: this.no_used_reason,
      };
      this.saveLoading = true;
      failExamine(data)
        .then((res) => {
          this.no_used_reason = "";
          this.closeModal();
          this.$emit("updateList");
          this.saveLoading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.dialog-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
</style>
