<template>
  <div class="list-page">
    <ExamineScreen :filterForm="examineForm" :uploadFilter="callbackForm"></ExamineScreen>
    <list-tabs v-model="examineForm.tab" :isLoading="show_loading" :tabs="tabArr"
      @tab-click="handleChangeTab">
    </list-tabs>
    <div class="list-content" v-loading="show_loading">
      <div class="list-btn">
        <el-button v-if="examineForm.tab != 'checked'" type="success" size="small"
          @click="handleAgreeExamine">通过
        </el-button>
      </div>
      <el-table style="width: 100%" class="thead-light" stripe :data="examine_list"
        @selection-change="handleSelection" @sort-change="sortChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column show-overflow-tooltip v-for="(item,index) in examineTable" :prop="item.prop"
          :label="item.label" :min-width="item.min_width" :key="index" :sortable="item.sortable">
          <template slot-scope="scope">
            <span v-if="item.prop === 'title'" style="cursor: pointer;"
              @click="previewArticle(scope.row)">{{scope.row.title}}</span>
            <span
              v-else-if="(examineForm.tab !== 'unchecked') || (item.prop !== 'status_text')">{{scope.row[item.prop] | placeholder}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" v-if="examineForm.tab ==='checking'" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="text" @click="toArticleDetail(scope.row.id, scope.row.portal_user_id, scope.row)">编辑
              </el-button>
              <el-button type="text" @click="handleShowFail(scope.row.id)">不通过</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage">
    </Pagination>
    <NotFail :id="failId" :showFail="showFail" @closeNotFail="showFail = false" @updateList="updateList">
    </NotFail>
    <article-viewer v-model="preview.show" :options="preview.data"></article-viewer>
  </div>
</template>

<script>
  import ExamineScreen from "../components/ExamineArticle/ExamineScreen";
  import {agreeExamine, examineList} from "../api/examine-list";
  import Pagination from "@/base/components/Default/Pagination";
  import NotFail from "../components/ExamineArticle/NotFail";
  import ArticleViewer from "@/base/components/Preview/ArticleViewer";
  import previewArticle from '../mixins/previewArticle'

  export default {
  name: "examine-article",
    mixins: [previewArticle],
  data() {
    return {
      examineForm: {
        page_size: 15,
        tab: "checking",
        category_id: 0,
        type: -1,
        start_time: -1,
        end_time: -1,
        keyword: "",
        is_desc: 1,
        author_name: "",
        user_id: "",
        user_type: "",
      },
      filters: {
        checking: null,
        unchecked: null,
        checked: null,
      },
      pageData: {},
      //tab栏
      tabArr: [
        {
          name: "checking",
          label: "待审核",
        },
        {
          name: "unchecked",
          label: "未通过",
        },
        {
          name: "checked",
          label: "已审核",
        },
      ],
      //审核列表
      examine_list: [],
      examineTable: [
        { label: "ID", prop: "id", min_width: 100 },
        { label: "标题", prop: "title", min_width: 200 },
        { label: "来源", prop: "source_name", min_width: 120 },
        { label: "分类", prop: "category_name", min_width: 100 },
        { label: "作者", prop: "author_name", min_width: 120 },
        { label: "状态", prop: "status_text", min_width: 100 },
        {
          label: "创建时间",
          prop: "create_time",
          min_width: 160,
          sortable: true,
        },
      ],
      show_loading: false,
      select_id: [],
      //不通过弹窗
      showFail: false,
      failId: 0,
      preview: {
        show: false,
        data: {},
      },
    };
  },
  components: {
    ExamineScreen,
    Pagination,
    NotFail,
    ArticleViewer,
  },
  created() {
    const requestData = this.$route.query?.tab
      ? { ...this.examineForm, ...this.$route.query }
      : this.examineForm;
    this.examineForm.tab = this.$route.query?.tab
      ? this.$route.query.tab
      : this.examineForm.tab;
    this.show_loading = true;
    this.getExamineList(requestData);
  },
  methods: {
    sortChange(e) {
      this.examineForm.order_by = e.prop;
      if (e.order == "ascending") {
        this.examineForm.is_desc = 0;
      } else if (e.order == "descending") {
        this.examineForm.is_desc = 1;
      }
      this.refreshList();
    },
    handleAgreeExamine() {
      if (this.select_id.length === 0) {
        this.$message({
          message: "请选择你要用过的文章",
          type: "warning",
        });
        return;
      }
      this.$confirm("确定要通过该文章？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.show_loading = true;
          agreeExamine({ id: this.select_id })
            .then(() => {
              this.$message({
                message: "审核成功",
                type: "success",
              });
              this.select_id = [];
              this.refreshList();
            })
            .catch(() => {})
            .finally(() => {
              this.show_loading = false;
            })
        })
        .catch(() => {});
    },
    updateList() {
      this.showFail = false;
      this.failId = 0;
      this.refreshList();
    },
    //刷新列表
    refreshList() {
      this.getExamineList({
        ...this.examineForm,
        page: this.pageData.current_page,
      });
    },
    handleShowFail(id) {
      this.failId = id;
      this.showFail = true;
    },
    toArticleDetail(id, portal_user_id, row) {
      if (row.article_type) {
        this.$router.push({
          path: "/admin/article-admin/examine/edit-wx-article",
          query: {
            id: id,
            portal_user_id: portal_user_id,
            come_from: "ArticleCheckList",
          },
        });
      } else {
        this.$router.push({
          path: "/admin/article-admin/examine/edit-article",
          query: {
            id: id,
            portal_user_id: portal_user_id,
            come_from: "ArticleCheckList",
          },
        });
      }
    },
    handleSelection(data) {
      let arr = [];
      data.forEach((item, index) => {
        arr.push(item.id);
      });
      this.select_id = arr;
    },
    changPage(e) {
      this.examineForm.page_size = e.page_size;
      const data = { ...this.examineForm, page: e.page };
      this.getExamineList(data);
    },
    callbackForm(e) {
      this.examineForm = e;
      this.getExamineList(this.examineForm);
    },
    handleChangeTab(e) {
      if (!this.filters[e.name]) {
        this.examineForm = {
          page_size: this.pageData.per_page,
          tab: e.name,
          category_id: 0,
          type: -1,
          start_time: -1,
          end_time: -1,
          keyword: "",
          is_desc: 1,
          author_name: "",
          user_id: "",
          user_type: "",
        };
      } else {
        this.examineForm = this.filters[e.name];
      }
      this.getExamineList(this.examineForm);
    },
    getExamineList(requestData) {
      this.examine_list = [];
      this.pageData = {};
      this.show_loading = true;
      examineList(requestData)
        .then((res) => {
          this.examine_list = res.data.data;
          this.pageData = res.data;
          this.filters[res.data.tab] = { ...this.examineForm };
          this.show_loading = false;
        })
        .catch(() => {
          this.show_loading = false;
        });
    },
  },
};
</script>
